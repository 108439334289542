import type { SubmitButtonTranslationObj } from "../../translation-keys/submit-button-text-keys.js";

const DownloadSubmitButtonText = "ダウンロードする";
const SubmitButtonMapText: SubmitButtonTranslationObj = {
"document-Submit-Button": DownloadSubmitButtonText,
"document-landing-page-Submit-Button": DownloadSubmitButtonText,
"post-event-assets-Submit-Button": "すぐにアクセスする",
"ppc-Submit-Button": DownloadSubmitButtonText,
"ppc-contact-Submit-Button": "回答を入手する",
"case-study-Submit-Button": "ケーススタディを無料でダウンロード",
"video-Submit-Button": DownloadSubmitButtonText,
"video-landing-page-Submit-Button": "送信して再生",
"contact-Submit-Button": "送信する",
"qx-roi-Submit-Button": "ROI評価を今すぐスケジュール",
"mx-roi-Submit-Button": "ROI評価を今すぐスケジュール",
"spark-Submit-Button": "Sparkのエキスパートに問い合わせる",
"pricing-Submit-Button": "見積もりを依頼",
"subscription-Submit-Button": "登録",
"partner-Submit-Button": "送信",
"demo-request-Submit-Button": "デモをスケジュール",
"tradeshow-giveaway-Submit-Button": "送信",
"event-registration-Submit-Button": "登録",
"subprocessor-update-notification-Submit-Button": "送信",
"digital-maturity-assessment-Submit-Button": "Access My Full Report",
"qx-demo-video-Submit-Button": "Watch the Demo!",
"video-gated-Submit-Button": "",
"ppc-video-Submit-Button": "Watch the Video"
};

export default SubmitButtonMapText;

